<template>
  <v-main>
    <v-card>
      <v-toolbar color="#FB9300">
        <v-toolbar-title>List Point Pelanggan</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-btn icon>
          <v-icon @click="addPoint()">mdi-plus-circle</v-icon>
        </v-btn>
        <!-- <v-btn icon>
          <v-icon @click="aa()">mdi-plus-circle</v-icon>
        </v-btn> -->
      </v-toolbar>

      <v-data-table
        fixed-header
        :headers="headers"
        :items="point"
        :search="search"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-btn class="ma-2" color="primary" small @click="clicked(item)">
            Detail
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogAddData" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> Tambah Point User </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.user_id"
                    label="User ID"
                    hint="Inputkan User ID"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.point"
                    label="Point Pelanggan"
                    hint="Inputkan Jumlah Point"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  {{ form.note }}
                  <v-radio-group v-model="form.note" mandatory>
                    <v-radio label="Referral" value="Referral"></v-radio>
                    <v-radio
                      label="Download dan Login"
                      value="First Login"
                    ></v-radio>
                    <v-radio label="Salah Input" value="Wrong Input"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="dialogAddData = false"> Close </v-btn>

          <v-btn color="success" @click="checkDataSaved()"> Save Data </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> Detail </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-data-table
              fixed-header
              :headers="headersDetail"
              :items="detailData"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`item.created`]="{ item }">
                {{
                  new Date(item.created).toLocaleDateString("id-ID", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                }}
              </template>
            </v-data-table>
          </v-container>
          <small>*detail data</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="(dialog = false), reset()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
// import { collection, getFirestore } from "firebase/firestore";
import moment from "moment";

import { getAuth } from "firebase/auth";
import Swal from "sweetalert2";

// const db = collection(getFirestore(), "news");
// const timeNow = moment().format("MMMM Do YYYY, h:mm:ss A ");

export default {
  name: "News",
  data() {
    return {
      load: false,
      dialogProgress: false,
      snackbar: false,
      valid: true,
      error_message: "",
      dialog: false,
      editing: false,
      dialogAddData: false,
      loader: null,
      loading: false,
      loading2: false,
      loading3: false,
      loading4: false,
      loading5: false,
      point: [],
      search: "",
      headers: [
        { text: "User ID", align: "center", value: "_id", width: "40%" },
        { text: "Point Value", value: "total", width: "30%" },
        { text: "Pengaturan", value: "action", width: "20%" },
      ],
      headersDetail: [
        { text: "User ID", align: "start", value: "user_id" },
        { text: "Point", value: "point" },
        { text: "Keterangan", value: "note" },
        { text: "Tanggal Dibuat", value: "created" },
      ],
      detailData: [],
      form: {
        note: null,
        point: null,
        user_id: null,
        admin: null,
      },
    };
  },

  filters: {
    moment: function (date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
  },

  methods: {
    clicked(item) {
      this.$axios({
        method: "GET",
        url: `https://us-central1-payment-gateway-pf.cloudfunctions.net/pfgw/points/${item._id}`,
        headers: {
          Authorization: "Basic YWRtaW46S210cG8hbnQ5OQ==",
        },
      }).then((result) => {
        this.detailData = result.data;
        console.log(this.detailData);
        this.dialog = true;
      });
    },

    addPoint() {
      this.dialogAddData = true;
      this.form.admin = getAuth().currentUser.email;
      this.form.user_id = "";
      this.form.point = 0;
      this.form.note = "";
    },
    checkDataSaved() {
      if (
        this.form.admin == "" ||
        this.form.user_id == "" ||
        this.form.point == 0
      ) {
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: "Mohon isi data terlebih dahulu...",
          confirmButtonColor: "#d33",
          timer: 3000,
        });
      } else {
        this.saveDataPoint();
      }
    },
    saveDataPoint() {
      console.log(this.form);
      console.log();
      this.$axios({
        method: "POST",
        url: "https://us-central1-payment-gateway-pf.cloudfunctions.net/pfgw/points",
        headers: {
          Authorization: "Basic YWRtaW46S210cG8hbnQ5OQ==",
        },
        data: {
          note: this.form.note,
          point: parseInt(this.form.point),
          user_id: this.form.user_id,
          admin: this.form.admin,
        },
      }).then((result) => {
        Swal.fire({
          icon: "success",
          title: "Sukses",
          text: "Data berhasil ditambahkan",
          confirmButtonColor: "#4CAF50",
          timer: 3000,
        });
        this.realTImeReadData();
        this.dialogAddData = false;
        console.log(result);
      });
    },
    testing() {
      console.log("testing");
      const baseURI =
        "https://us-central1-payment-gateway-pf.cloudfunctions.net/pfgw/points";
      this.$axios.get(baseURI).then((result) => {
        this.users = result.data;
        console.log(this.users);
      });
    },

    realTImeReadData() {
      this.$axios({
        method: "GET",
        url: "https://us-central1-payment-gateway-pf.cloudfunctions.net/pfgw/users_balance",
        headers: {
          Authorization: "Basic YWRtaW46S210cG8hbnQ5OQ==",
        },
      })
        .then((result) => {
          this.point = result.data;
          console.log(this.point[0].total);
        })
        .catch((err) => {
          this.realTImeReadData();

          console.log(err);
        });
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },

  mounted() {
    this.realTImeReadData();
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
};
</script>

<style></style>
